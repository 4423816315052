import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { FlexCol, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
  DsiCountSetter as CountSetter,
} from 'eshop-defaults';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import { formatPrice } from '../../utilities';
import { addItemToCart } from '../../containers/Cart/cartSlice';
import { Loader } from '../_helpers/Loader/Loader';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import OutsideClick from '../_helpers/Default/OutsideClick';
import { Modal } from '../_helpers/Modal/Modal';

interface Props {
  handleClose: () => void;
  handleLeftButtonClick: () => void;
  handleRightButtonClick: () => void;
  titleText: string;
  body: string;
  leftButtonText: string;
  rightButtonText: string;
  enableCloseIcon: boolean;
  enableOutsideClick: boolean;
}

function ChooseModal(props) {
  const {
    titleText,
    body,
    handleClose,
    buttons,
    desktopButtonsLayout,
    enableCloseIcon,
    enableOutsideClick,
  } = props;
  const OutsideWrapper = enableOutsideClick ? OutsideClick : styled.div``;

  return (
    <>
      {/* <Dimmer height={100} zIndex={990} topZero={true} /> */}
      <OutsideWrapper handleFunction={handleClose}>
        <AddModal>
          <Wrapper>
            <Heading>
              <Title>{titleText}</Title>
              {enableCloseIcon ? (
                <IconWrapper onClick={handleClose}>
                  <StyledSvgIcon
                    icon={IconType.close}
                    width={24}
                    height={24}
                    fill={'#414141'}
                  />
                </IconWrapper>
              ) : (
                <></>
              )}
            </Heading>
            <BodyWrapper>
              <div dangerouslySetInnerHTML={{ __html: body }} />
            </BodyWrapper>
            <Buttons desktopButtonsLayout={desktopButtonsLayout}>
              {buttons.map(button => {
                return (
                  <MiddleButton
                    onClick={button.handleClick}
                    backgroundColor={button.backgroundColor}
                    textColor={button.textColor}
                  >
                    {button.text}
                  </MiddleButton>
                );
              })}
            </Buttons>
          </Wrapper>
        </AddModal>
      </OutsideWrapper>
    </>
  );
}

const BodyWrapper = styled.div`
  color: black;
`;

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
`;

const AddModal = styled(StyledModal)`
  max-width: ${rem(500)};
  width: 95%;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(24)};
`;

const Title = styled(TextP)`
  font-size: ${rem(20)};
  font-weight: 500;
  line-height: ${rem(25)};
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(12)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ProductWrapper = styled(FlexRowCenterVertical)`
  margin-bottom: ${rem(40)};
  justify-content: space-between;
  flex-flow: row wrap;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)<{ desktopButtonsLayout }>`
  justify-content: space-between;
  margin-top: ${rem(10)};
  gap: ${rem(20)};

  ${({ desktopButtonsLayout }) =>
    desktopButtonsLayout === 'column'
      ? `flex-direction: column;gap: ${rem(5)};`
      : ``}

  ${({ theme }) => theme.mediab.m580`
    flex-direction: column;
    gap: ${rem(5)};
  `}
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
  `}
`;

const MiddleButton = styled(StyledButton)<{ backgroundColor; textColor }>`
  color: ${({ textColor, theme }) =>
    textColor ? textColor : theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ? backgroundColor : theme.color.white};
  width: 100%;

  ${({ theme }) => theme.mediab.m580`
    width:100%;
  `}
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const Plu = styled(Name)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.inactiveFontColor};
`;

const Price = styled(Name)`
  min-width: ${rem(56)};
  text-align: left;

  ${({ theme }) => theme.mediab.m580`
    margin-bottom: ${rem(16)};
  `}
`;

const AddToCartButton = styled(Button)`
  text-decoration: none;
`;

export { ChooseModal };
