import * as React from 'react';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import {
  customerInfoSelector,
  fetchDeliveryAddresses,
  deliveryAddressesSelector,
  deliveryAddressesIsFetchingSelector,
  fetchCustomerDetails,
  fetchCustomerFinancialDetails,
} from './myAccountSlice';
import MyDeliveryAddresses from '../../components/MyAccount/MyDeliveryAddresses';
import API, { ThenArg } from '../../services/API';
import {
  BreadCrumbType,
  setBreadCrumbPath,
} from '../BreadCrumb/breadCrumbSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import { __ } from 'react-i18n';
import { langSelector } from '../App/selectors';
import { cartDataSelector, replaceCurrentCart } from '../Cart/cartSlice';

interface Props {
  user: any;
  dispatch: any;
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  isFetching: boolean;
  lang: string;
  cart: any;
}

class MyDeliveryAddressesContainer extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      dispatch(setBreadCrumbPath(BreadCrumbType.MY_ACCOUNT_DELIVERY_ADDRESSES));
      await Promise.all([
        await dispatch(fetchDeliveryAddresses(true)),
        await dispatch(fetchCustomerDetails()),
        await dispatch(fetchCustomerFinancialDetails()),
      ]);
      return;
    } catch (exp) {
      // console.log(exp);
      return;
    }
  }

  public render() {
    const { user, deliveryAddresses, isFetching, lang } = this.props;

    return (
      <>
        <MetaTags tags={{ title: __('Moj účet - Moje dodacie adresy') }} />
        <MyDeliveryAddresses
          isFetching={isFetching}
          userId={user && user.id}
          isB2B={user && user.b2b ? true : false}
          isB2C={user && !user.b2b}
          deliveryAddresses={deliveryAddresses}
          refreshAddresses={this.refreshAddresses}
          lang={lang}
          refreshCart={this.refreshCart}
        />
      </>
    );
  }

  public refreshAddresses = async () => {
    const { dispatch } = this.props;
    try {
      await dispatch(fetchDeliveryAddresses(true));
    } catch (exp) {
      // console.log(exp);
    }
  };

  public refreshCart = async () => {
    const { dispatch, cart } = this.props;

    if (cart?.id) {
      await dispatch(replaceCurrentCart(cart.id));
    }
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    customerInfo: customerInfoSelector(state),
    deliveryAddresses: deliveryAddressesSelector(state),
    isFetching: deliveryAddressesIsFetchingSelector(state),
    lang: langSelector(state),
    cart: cartDataSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'MyDeliveryAddressesContainer' })(
    MyDeliveryAddressesContainer,
  ),
);
