import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { withTheme } from 'styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  ZemplinButton as Button,
  InfoText,
  DsiAddressesDeliveryList as AddressesDeliveryList,
  DsiAddressChangeForm as AddressChangeForm,
  ZemplinLoaderWrapper,
  FlexRowCenterVertical,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import {
  IconType,
  SvgIcon,
} from 'eshop-defaults/lib/components/Zemplin/General/SvgIcon';
import { prop } from '../../utilities';
import {
  getPhonePrefix,
  getPhoneWithoutPrefix,
} from 'eshop-defaults/lib/utilities';
import { removePhonePrefix } from 'eshop-defaults';
import { phonePrefixes } from 'eshop-defaults';

interface Props {
  userId: number;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
  refreshAddresses: () => void;
  isFetching: boolean;
  theme: any;
  isB2B: boolean;
  lang: string;
  isB2C: boolean;
  refreshCart: () => void;
}

function reducer(state, { field, value }) {
  return { ...state, [field]: value };
}

function MyDeliveryAddresses({
  userId,
  deliveryAddresses,
  refreshAddresses,
  isFetching,
  theme,
  isB2B,
  lang,
  isB2C,
  refreshCart,
}: Props) {
  let initialState = {};
  const defaultPrefixBasedOnLang = lang === 'cz' ? '+420' : '+421';
  if (deliveryAddresses.length > 0) {
    initialState = {
      country: deliveryAddresses[0].country,
      country_id: lang === 'cz' ? 'cz' : 'sk',
      streetNumber: deliveryAddresses[0].delivery_number,
      city: deliveryAddresses[0].delivery_city,
      zip: deliveryAddresses[0].delivery_zip,
      street: deliveryAddresses[0].delivery_street,
      name: deliveryAddresses[0].name,
      surname: ' ',
      company: '',
      delivery_phone: '',
      delivery_phone_prefix: defaultPrefixBasedOnLang,
      delivery_email: '',
      isDefault: true,
    };
  }
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState('');
  const [error, setError] = React.useState(null);
  const [currentId, setCurrentId] = React.useState(
    isB2B ? prop(deliveryAddresses, '0.id', null) : null,
  );

  const onChange = e => {
    let {
      target: { name, value },
    } = e;

    if (name === 'delivery_phone') {
      value = removePhonePrefix(phonePrefixes, value);
    }

    dispatch({ field: e.target.name, value: e.target.value });
  };

  React.useEffect(() => {
    setCurrentId(prop(deliveryAddresses, '0.id', null));
    dispatch({
      field: 'country_id',
      value: prop(deliveryAddresses, '0.country', null),
    });
    dispatch({
      field: 'streetNumber',
      value: prop(deliveryAddresses, '0.delivery_number', null),
    });
    dispatch({
      field: 'city',
      value: prop(deliveryAddresses, '0.delivery_city', null),
    });
    dispatch({
      field: 'zip',
      value: prop(deliveryAddresses, '0.delivery_zip', null),
    });
    dispatch({
      field: 'street',
      value: prop(deliveryAddresses, '0.delivery_street', null),
    });
    dispatch({
      field: 'delivery_phone',
      value: getPhoneWithoutPrefix(
        prop(deliveryAddresses, '0.delivery_phone', null),
      ),
    });
    dispatch({
      field: 'delivery_phone_prefix',
      value:
        getPhonePrefix(prop(deliveryAddresses, '0.delivery_phone', null)) ||
        defaultPrefixBasedOnLang,
    });
    dispatch({
      field: 'delivery_email',
      value: prop(deliveryAddresses, '0.delivery_email', null),
    });
    dispatch({ field: 'name', value: prop(deliveryAddresses, '0.name', null) });
    dispatch({
      field: 'surname',
      value: prop(deliveryAddresses, '0.surname', null),
    });
    dispatch({
      field: 'isDefault',
      value: prop(deliveryAddresses, '0.isDefault', null),
    });
    dispatch({
      field: 'company',
      value: prop(deliveryAddresses, '0.delivery_company', null),
    });
  }, [deliveryAddresses]);

  const {
    country_id,
    streetNumber,
    street,
    city,
    zip,
    isDefault,
    name,
    surname,
    company,
    delivery_phone,
    delivery_phone_prefix,
    delivery_email,
    delivery_company,
  } = state;
  const handleCurrentAddressChange = id => {
    setCurrentId(id);
    const currentAddress = (deliveryAddresses as any).find(a => a.id === id);
    if (currentAddress) {
      const {
        delivery_street,
        delivery_number,
        delivery_zip,
        delivery_city,
        delivery_phone,
        delivery_email,
        country,
        name: addrName,
        surname: addrSurname,
        isDefault: isDefaultAddress,
        delivery_company,
      } = currentAddress;
      dispatch({ field: 'country_id', value: country || lang });
      dispatch({ field: 'streetNumber', value: delivery_number });
      dispatch({ field: 'city', value: delivery_city });
      dispatch({ field: 'zip', value: delivery_zip });
      dispatch({ field: 'street', value: delivery_street });
      dispatch({ field: 'zip', value: delivery_zip });
      dispatch({
        field: 'delivery_phone',
        value: getPhoneWithoutPrefix(delivery_phone),
      });
      dispatch({
        field: 'delivery_phone_prefix',
        value: getPhonePrefix(delivery_phone) || defaultPrefixBasedOnLang,
      });
      dispatch({ field: 'delivery_email', value: delivery_email });
      dispatch({ field: 'name', value: addrName });
      dispatch({ field: 'surname', value: addrSurname });
      dispatch({ field: 'isDefault', value: isDefaultAddress });
      dispatch({ field: 'company', value: delivery_company });
    } else {
      dispatch({ field: 'country_id', value: lang === 'cz' ? 'cz' : 'sk' });
      dispatch({ field: 'streetNumber', value: '' });
      dispatch({ field: 'city', value: '' });
      dispatch({ field: 'zip', value: '' });
      dispatch({ field: 'delivery_phone', value: isB2C ? '' : delivery_phone });
      dispatch({
        field: 'delivery_phone_prefix',
        value: isB2C ? defaultPrefixBasedOnLang : '',
      });
      dispatch({ field: 'delivery_email', value: isB2C ? '' : delivery_email });
      dispatch({ field: 'street', value: '' });
      dispatch({ field: 'name', value: isB2C ? '' : name });
      dispatch({ field: 'surname', value: isB2C ? '' : surname });
      dispatch({ field: 'isDefault', value: false });
      dispatch({ field: 'company', value: isB2C ? '' : delivery_company });
    }
  };

  const deleteAddress = async () => {
    setIsSaving(true);
    try {
      if (currentId) {
        await API.deleteDeliveryAddress(userId, currentId || -1, {});
      }
      refreshAddresses();
    } catch (e) {
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setCurrentId(null);
    handleCurrentAddressChange(null);
    setDataUpdated(__('Adresa bola úspešne zmazaná'));
  };

  const updateDetails = async e => {
    e.preventDefault();
    setIsSaving(true);
    try {
      if (currentId) {
        await API.updateDeliveryAddress(
          userId,
          currentId || -1,
          {},
          {
            ...state,
            delivery_street: street,
            delivery_number: streetNumber,
            delivery_zip: zip,
            delivery_city: city,
            name: name,
            surname: isB2C ? surname : ' ',
            company,
            delivery_phone: `${delivery_phone_prefix}${delivery_phone}`,
            delivery_phone_prefix,
            delivery_email,
            country: lang === 'cz' ? 'cz' : country_id || 'sk',
            isDefault,
          },
        );
        handleCurrentAddressChange(null);
        refreshCart();
      } else {
        await API.createNewDeliveryAddress(
          userId,
          {},
          {
            ...state,
            delivery_street: street,
            delivery_number: streetNumber,
            delivery_zip: zip,
            delivery_city: city,
            name: name,
            surname: isB2C ? surname : ' ',
            company,
            delivery_phone: `${delivery_phone_prefix}${delivery_phone}`,
            delivery_phone_prefix,
            delivery_email,
            country:
              lang === 'cz'
                ? 'cz'
                : (country_id === 'sk' ? 'Slovensko' : country_id) || 'sk',
            country_id: lang === 'cz' ? 'cz' : country_id || 'sk',
            isDefault,
          },
        );
        setCurrentId(null);
        handleCurrentAddressChange(null);
      }
      refreshAddresses();
    } catch (e) {
      setError(e);
      setDataUpdated('');
      setIsSaving(false);
      return;
    }
    setError(null);
    setIsSaving(false);
    setDataUpdated(__('Údaje boli úspešne zmenené'));
  };

  return (
    <Wrapper>
      {isFetching ? (
        <ZemplinLoaderWrapper height={500} />
      ) : (
        <>
          <Title marginTop={24}>{__('Moje dodacie adresy')}</Title>
          <AddressesDeliveryList
            onAddressClick={handleCurrentAddressChange}
            addresses={deliveryAddresses}
            currentId={currentId}
            isB2B={isB2B}
          />

          <form>
            <DimWrapper dimmed={isSaving}>
              <AddressChangeForm
                onChange={onChange}
                street={street}
                streetNumber={streetNumber}
                city={city}
                zip={zip}
                name={name}
                surname={surname}
                company={company}
                delivery_phone={delivery_phone}
                delivery_phone_prefix={delivery_phone_prefix}
                delivery_email={delivery_email}
                isPreffered={isDefault}
                country_id={country_id}
                isNew={currentId === null}
                isB2B={isB2B}
                lang={lang}
                formProblems={prop(error, 'details.payload.problems')}
              />
            </DimWrapper>

            <ButtonWrapper>
              <SaveButton type="submit" onClick={updateDetails}>
                {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
              </SaveButton>
              {!isB2B && currentId && (
                <DeleteButton onClick={deleteAddress}>
                  <SvgIcon
                    width={16}
                    height={16}
                    marginRight={16}
                    icon={IconType.trash}
                    viewBox="0 0 16 16"
                    alt={__('Odstrániť adresu')}
                    fill={theme.colors.textPrimary}
                    cursor={'pointer'}
                  />
                  {isSaving ? __('Odstráňujem...') : __('Odstrániť adresu')}
                </DeleteButton>
              )}
            </ButtonWrapper>

            {!error && dataUpdated && (
              <InfoText type={InfoType.SUCCESS} info={dataUpdated} />
            )}
            {error && (
              <InfoText
                type={InfoType.ERROR}
                info={
                  prop(error, 'details.payload.problems')
                    ? prop(error, 'details.description')
                    : __('Pri ukladaní sa vyskytla chyba')
                }
              />
            )}
          </form>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const DimWrapper = styled(FlexCol)<{ dimmed: boolean }>`
  opacity: ${({ dimmed }) => (dimmed ? 0.4 : 1)};
`;

const ButtonWrapper = styled(FlexRowCenterVertical)`
  flex-flow: row wrap;
`;

const SaveButton = styled(Button)`
  height: ${rem(54)};
  padding: ${rem(18)} ${rem(32)};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(16)};
  font-weight: 500;
  border-radius: ${rem(2)};
  margin: 0 0 ${rem(24)};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  &:disabled {
    background-color: #dcd6d6;
  }

  ${({ theme }) => theme.mediab.m580`
    max-width: initial;
  `}
`;

const DeleteButton = styled(SaveButton)`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: ${rem(24)};
  border: 1px solid #ccc;
  max-width: ${rem(220)};
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  ${({ theme }) => theme.mediab.m580`
     margin-left: 0;
  `}
`;

export default withTheme(MyDeliveryAddresses);
