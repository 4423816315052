import * as React from 'react';
import { __ } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  DsiContactInfoBlock as ContactInfoBlock,
  DsiInvoiceAddressBlock as InvoiceAddressBlock,
  DsiCompanyInfo as CompanyInfo,
  ZemplinButton as Button,
  InfoText,
  DsiSvgIcon as SvgIcon,
  DsiIconType as IconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { InfoType } from 'eshop-defaults/lib/components/Zemplin/General/InfoText';
import { StyledTitle } from './MyDashboard';
import { FlexRowCenterVertical, TextP } from 'eshop-defaults/lib';
import { prop } from '../../utilities';
import {
  getPhonePrefix,
  getPhoneWithoutPrefix,
} from 'eshop-defaults/lib/utilities';
import { removePhonePrefix } from 'eshop-defaults';
import { phonePrefixes } from 'eshop-defaults';

interface Props {
  customerInfo: ThenArg<typeof API.getCustomerDetails>;
  userId: number;
  isB2B: boolean;
  lang: string;
  deliveryAddresses: ThenArg<typeof API.getDeliveryAddresses>;
}

function MyInfo({
  customerInfo,
  userId,
  isB2B,
  lang,
  deliveryAddresses,
}: Props) {
  const companyRef: any = React.useRef() as React.MutableRefObject<
    HTMLInputElement
  >;
  let deliveryAddressDefault = deliveryAddresses
    ? deliveryAddresses.find(d => d.isDefault)
    : null;

  if (
    deliveryAddresses &&
    deliveryAddresses.length &&
    !deliveryAddressDefault
  ) {
    deliveryAddressDefault = deliveryAddresses[0];
  }
  const {
    meno,
    priezvisko,
    email,
    ico: currentIco,
    dic: currentDic,
    icdph: currentIcDph,
    firma,
    country: currentCountry,
    cislo,
    mesto,
    psc,
    telefon,
    ulica,
  } = customerInfo;

  // const isNameSet = meno ? meno !== '' : false;
  // const isSurnameSet = priezvisko ? priezvisko !== '' : false;
  const defaultPhone =
    telefon || prop(deliveryAddressDefault, 'delivery_phone') || '';
  const initialState = {
    name: meno || '',
    surname: priezvisko || '',
    ico: currentIco || '',
    dic: currentDic || '',
    icdph: currentIcDph || '',
    company: firma || '',
    country: currentCountry || 'sk',
    streetNumber: cislo || '',
    city: mesto || '',
    zip: psc || '',
    phone: getPhoneWithoutPrefix(defaultPhone),
    phonePrefix: getPhonePrefix(defaultPhone)
      ? getPhonePrefix(defaultPhone)
      : lang === 'cz'
      ? '+420'
      : '+421',
    street: ulica || '',
    isCompany: firma && currentIco && currentDic && currentIcDph,
    vatPayer: customerInfo?.platca_dph ? customerInfo?.platca_dph : false,
  };
  const [state, setState] = React.useState<any>(initialState);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [problems, setProblems] = React.useState<any>(null);

  React.useEffect(() => {
    setState(initialState);
  }, [customerInfo]);

  const handleVatPayerChange = e => {
    setState({ ...state, vatPayer: !state.vatPayer });
  };

  const onChange = e => {
    let {
      target: { name, value },
    } = e;

    if (name === 'phone') {
      value = removePhonePrefix(phonePrefixes, value);
    }

    setState({ ...state, [name]: value });
    if (name === 'isCompany' && value) {
      if (companyRef && companyRef.current) {
        window.scrollTo({
          behavior: 'smooth',
          top: companyRef.current.offsetTop,
        });
      }
    }
  };

  const {
    ico,
    dic,
    icdph,
    company,
    country,
    streetNumber,
    street,
    city,
    zip,
    phone,
    phonePrefix,
    isCompany,
    name,
    surname,
    vatPayer,
  } = state;

  const updateDetails = async () => {
    setIsSaving(true);
    try {
      await API.updateCustomerDetails(
        userId,
        {},
        {
          ...customerInfo,
          meno: name,
          priezvisko: surname,
          firma: company,
          ico,
          dic,
          icdph,
          ulica: street,
          cislo: streetNumber,
          telefon: `${phonePrefix}${phone}`,
          phonePrefix,
          psc: zip,
          mesto: city,
          country,
          registrovany: prop(customerInfo, 'registrovany') || '',
          platca_dph: vatPayer ? 1 : 0,
        },
      );
    } catch (e) {
      if ((e as any)?.details?.payload?.problems) {
        setProblems((e as any).details.payload.problems);
      }
      setError(e);
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
    setDataUpdated(true);
    setError(null);
  };

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={40}>
        {isB2B ? __('Moje údaje') : __('Moje údaje B2C')}
      </StyledTitle>
      {/*<ContactPersonBlock*/}
      {/*    onChange={onChange}*/}
      {/*    isNameSet={isNameSet}*/}
      {/*    isSurnameSet={isSurnameSet}*/}

      {/*    isCompany={isCompany}*/}
      {/*/>*/}
      {isB2B && (
        <InfoBanner>
          <SvgIcon
            icon={IconType.info}
            width={24}
            height={24}
            marginRight={8}
            fill={'#FFFFFF'}
          />
          <InfoBannerText>
            {`${__('Ak si prajete zmeniť údaje, kontaktujte nás na')} ${__(
              'zakaznik@dsi.sk',
            )}.`}
          </InfoBannerText>
        </InfoBanner>
      )}
      {(!!email ||
        !!prop(deliveryAddressDefault, 'delivery_email') ||
        !!phone) && (
        <ContactInfoBlock
          email={email || prop(deliveryAddressDefault, 'delivery_email') || ''}
          phone={phone}
          phonePrefix={phonePrefix}
          onChange={onChange}
          isB2B={isB2B}
          problems={problems}
        />
      )}
      <InvoiceAddressBlock
        onChange={onChange}
        name={isB2B ? (company && ico ? '' : name) : name}
        surname={isB2B ? (company && ico ? '' : surname) : surname}
        street={street}
        streetNumber={streetNumber}
        city={city}
        zip={zip}
        isCompany={isCompany}
        country={country}
        isB2B={isB2B}
        lang={lang}
        problems={problems}
      />
      <CompanyWrapper visible={true} ref={companyRef}>
        <CompanyInfo
          onChange={onChange}
          name={company}
          ico={ico}
          dic={dic}
          icdph={icdph}
          isB2B={isB2B}
          lang={lang}
          vat_payer={vatPayer}
          handleVatPayerChange={handleVatPayerChange}
          problems={problems}
        />
      </CompanyWrapper>
      {!error && dataUpdated && (
        <InfoText
          type={InfoType.SUCCESS}
          info={__('Údaje boli úspešne zmenené')}
        />
      )}
      {error && (
        <InfoText
          type={InfoType.ERROR}
          info={__('Pri ukladaní sa vyskytla chyba')}
        />
      )}
      {!isB2B && (
        <SaveButton onClick={updateDetails}>
          {isSaving ? __('Ukladám údaje ...') : __('Uložiť')}
        </SaveButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

const CompanyWrapper = styled.div<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  height: ${({ visible }) => (visible ? 'auto' : 0)};
`;

const SaveButton = styled(Button)`
  height: ${rem(48)};
  padding: ${rem(15)};
  width: 100%;
  max-width: ${rem(104)};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${rem(16)};
  font-weight: 500;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
  margin: 0 0 ${rem(24)};

  &:disabled {
    background-color: #dcd6d6;
  }
`;

const InfoBanner = styled(FlexRowCenterVertical)`
  padding: ${rem(24)};
  background: #466ad9 0% 0% no-repeat padding-box;
  border-radius: ${rem(4)};
  margin-bottom: ${rem(40)};
`;

const InfoBannerText = styled(TextP)`
  color: ${({ theme }) => theme.colors.white};
`;

export default MyInfo;
